<template>
  <div>
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
    <tool-box></tool-box>

    <!-- 顶部大图 -->
    <a href="javascript:;" class="top">
      <el-image :src="advert.thumb" fit="cover"></el-image>
    </a>

    <div class="background-color-grey">
      <div class="container">
        <!-- 分类 -->
        <div class="bank-type">
          <el-row :gutter="20">
            <el-col :span="8" v-for="spec in specList" :key="spec.id">
              <div class="bank-type-item">
                <div class="bank-type-item-title">{{ spec.title }}</div>
                <el-row class="bank-type-item-children">
                  <el-col :span="12" v-for="child in spec.children" :key="child.id">
                    <span :title="child.title"><a href="javascript: void(0);" @click="goExamList(spec.id, child.id, null)">{{ child.title }}</a></span>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- 特色 -->
        <div class="bank-special">
          <el-divider>
            <div class="modular-title">题库特色<div class="title-bottom-line"></div></div>
          </el-divider>
          <!-- 竖起来 -->
          <div class="bank-special-content">
            <div class="bank-special-content-item" @click="goExamList(null, null, 7)">
              <div class="bank-special-content-img">
                <svg-icon iconClass="paper-moni" style="color: #F56C6C;"></svg-icon>
              </div>
              <div class="bank-special-content-info">
                <div class="bank-special-content-info-title">模拟试题</div>
                <div class="bank-special-content-info-desc">模拟真实考试现场，让你备考更轻松，考试通关更有把握！</div>
              </div>
            </div>
            <div class="bank-special-content-item" @click="goExamList(null, null, 2)">
              <div class="bank-special-content-img">
                <svg-icon iconClass="paper-miya" style="color: #E6A23C;"></svg-icon>
              </div>
              <div class="bank-special-content-info">
                <div class="bank-special-content-info-title">密押试卷</div>
                <div class="bank-special-content-info-desc">行业从教多年教师，根据每年考试试卷，分析命题规律，提高押题命中率!</div>
              </div>
            </div>
            <div class="bank-special-content-item" @click="goExamList(null, null, 3)">
              <div class="bank-special-content-img">
                <svg-icon iconClass="paper-lianxi" style="color: #E6A23C;"></svg-icon>
              </div>
              <div class="bank-special-content-info">
                <div class="bank-special-content-info-title">每日一练</div>
                <div class="bank-special-content-info-desc">依据新版无纸化考试标准，模拟考试流程，全面熟悉考试形式！</div>
              </div>
            </div>
            <div class="bank-special-content-item" @click="goExamList(null, null, 4)">
              <div class="bank-special-content-img">
                <svg-icon iconClass="paper-chapter" style="color: #409EFF;"></svg-icon>
              </div>
              <div class="bank-special-content-info">
                <div class="bank-special-content-info-title">章节练习</div>
                <div class="bank-special-content-info-desc">根据考试大纲，从海量试题中精选每个题型中的经典题，反复练习，轻松提升应考能力！</div>
              </div>
            </div>
            <div class="bank-special-content-item" @click="goExamList(null, null, 5)">
              <div class="bank-special-content-img">
                <svg-icon iconClass="paper-true" style="color: #F56C6C;"></svg-icon>
              </div>
              <div class="bank-special-content-info">
                <div class="bank-special-content-info-title">往年真题</div>
                <div class="bank-special-content-info-desc">收录归纳近10年考试真题，从题型和出题方向了解考试，更好的掌握考试节奏！</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 中心 -->
        <div class="bank-center">
          <el-divider>
            <div class="modular-title">题库中心<div class="title-bottom-line"></div></div>
          </el-divider>
          <el-row :gutter="20">
            <el-col :span="specList.length % 2 == 0 ? 12 : (index == (specList.length - 1) ? 24 : 12)" v-for="(spec, index) in specList" :key="spec.id">
              <div class="bank-center-content">
                <div class="bank-center-content-top">
                  <div class="bank-center-content-top-title">{{ spec.title }}</div>
                  <div class="bank-center-content-top-more" @click="goExamList(spec.id, spec.currentChildId, null)">更多></div>
                </div>
                <div class="bank-center-content-type">
                  <div :class="'bank-center-content-type-item' + (spec.currentChildId == child.id ? ' active' : '')"
                    @click="changeSpecChild(index, child)" v-for="child in spec.children" :key="child.id">
                    {{ child.title }}
                  </div>
                </div>
                <div class="bank-center-content-test">
                  <div class="bank-center-content-test-img">
                    <img :src="spec.thumb" alt="" />
                  </div>
                  <div class="bank-center-content-test-list">
                    <a href="javascript: void(0);" :title="paper.title" 
                      v-for="paper in spec.paperList" :key="paper.id" @click="goQuestion(paper)">
                      {{ paper.title }}
                    </a>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import SvgIcon from '@/components/svgicon'
import { getSpec, getPaperBySpecId, getAdvert } from '@/api/exam'
import { setSessionStorage, getSessionStorage ,getLocalStorage } from '@/utils/auth'
import {mapState,mapMutations} from 'vuex';

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
    SvgIcon,
  },
  data() {
    return {
      // 广告
      advert: {},
      // 专题列表
      specList: [],
    }
  },
  created() {
    getAdvert({pid: 19}).then(res => {
      if (res.code == 1) {
        this.advert = res.data[0]
      }
    })
    getSpec().then((res) => {
      if (res.code == 1) {
        this.specList = res.data.map(item => {
          item.currentChildId = item.children[0].id
          item.thumb = item.children[0].thumb
          item.paperList = []
          return item
        })
        this.specList.forEach((item, index) => {
          this.changeSpecChild(index, item.children[0])
        })
      }
    })
  },
  computed:{
  	...mapState(['token'])
  },
  methods: {
    // 进入试卷列表页面
    // spec：专题，child：二级专题，type：类型
    goExamList(specId, childId, type) {
      setSessionStorage('examListParams', {
        specId: specId || this.specList[0].id,
        specChildId: childId || this.specList[0].children[0].id,
        type: type || 7,
      })
      this.$router.push('/examlist')
    },
    // 切换子专题，并获取到试卷数据
    // specIndex：大专题索引位置
    // childId：二级专题id
    changeSpecChild(specIndex, child) {
      this.specList[specIndex].currentChildId = child.id
      this.specList[specIndex].thumb = child.thumb
      getPaperBySpecId({spec_id: child.id, page: 1, limit: 11}).then(res => {
        if (res.code == 1) {
          this.specList[specIndex].paperList = res.data
        }
      })
    },
    // 做题
    goQuestion(paper) {
		if(getLocalStorage('token')){
			setSessionStorage('currentPaper', paper)
			this.$router.push('/question')
		}else{
			this.$notify.error({
			  title: '未登录',
			  message: '请先登录'
			});
			return;
		}
    },

  },
};
</script>

<style scoped lang="scss">
/* 全局 begin */
// 这个是为了设置背景灰，现在背景默认就是灰色图片，就不需要单独设置这个了
.background-color-grey {
  // background-color: #eee;
  padding: 50px 0;
}
.el-divider {
  background-color: #999;
}
.modular-title {
  width: 180px;
  height: 70px;
  line-height: 70px;
  font-size: 30px;
  text-align: center;
}
.modular-title > .title-bottom-line {
  width: 60px;
  height: 3px;
  margin: 0 auto;
  background-color: $theme-color;
}
/* 全局 end */

/* top begin */
.top {
  width: 100%;
  height: 330px;
  display: inline-flex;

  .el-image {
    width: 100%;
  }
}
/* top begin */

/* 分类 begin */
.bank-type .bank-type-item {
  width: 100%;
  height: 180px;
  padding-top: 20px;
  margin: 20px 0;
  background-color: $background-color;
}
.bank-type .bank-type-item > .bank-type-item-title {
  height: 26px;
  line-height: 26px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.bank-type .bank-type-item > .bank-type-item-children {
  margin-top: 10px;
}
.bank-type .bank-type-item > .bank-type-item-children .el-col {
  color: #555;
  font-size: 15px;
  text-align: center;
  margin: 5px 0;
  border-right: 1px solid #ccc;
}
.bank-type .bank-type-item > .bank-type-item-children .el-col > span {
  display: inline-block;
  cursor: pointer;
}
.bank-type .bank-type-item > .bank-type-item-children .el-col:nth-child(2n) {
  border-right: none;
}
/* 分类 end */

/* 特色 begin */
.bank-special {
  margin-top: 120px;
}
.bank-special .bank-special-content {
  padding: 60px 5px;
  margin-top: 80px;
  background-color: $background-color;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  display: flex;
  justify-content: space-between;
}
.bank-special-content .bank-special-content-item {
  width: 220px;
  cursor: pointer;
}
.bank-special-content-item .bank-special-content-img {
  width: 80px;
  height: 80px;
  background: #f5f5f5;
  border-radius: 50%;
  margin: 0 auto;
}
.bank-special-content-img svg {
  width: 42px;
  height: 42px;
  margin: 21px 0 0 21px;
}
.bank-special-content-item .bank-special-content-info {
  text-align: center;
}
.bank-special-content-info .bank-special-content-info-title {
  color: #333;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: 500;
}
.bank-special-content-info .bank-special-content-info-desc {
  color: #666;
}
.bank-special-content-item:hover .bank-special-content-info-title,
.bank-special-content-item:hover .bank-special-content-info-desc {
  color: $theme-color;
}
/* 特色 end */

/* 题库中心 begin */
.bank-center {
  margin-top: 120px;
}
.bank-center > .el-row {
  margin-top: 80px;
}
.bank-center .bank-center-content {
  padding: 0 20px 10px;
  margin-bottom: 40px;
  border-radius: 2px;
  background: $background-color;
}
.bank-center-content > .bank-center-content-top {
  height: 50px;
  line-height: 50px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
}
.bank-center-content-top > .bank-center-content-top-title {
  font-size: 24px;
  font-weight: 400px;
  position: relative;
}
.bank-center-content-top > .bank-center-content-top-title::before {
  content: "";
  width: 6px;
  height: 24px;
  background: #333;
  margin-top: -12px;
  position: absolute;
  top: 50%;
  left: -10px;
}
.bank-center-content-top > .bank-center-content-top-more {
  cursor: pointer;
}
.bank-center-content > .bank-center-content-type {
  height: 82px;
  border-bottom: 1px solid #eee;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.bank-center-content-type > .bank-center-content-type-item {
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
}
.bank-center-content-type > .bank-center-content-type-item:hover,
.bank-center-content-type > .bank-center-content-type-item.active {
  color: $theme-color;
}
.bank-center-content-type > .bank-center-content-type-item:hover::before,
.bank-center-content-type > .bank-center-content-type-item.active::before {
  content: "";
  width: 47px;
  height: 3px;
  background-color: $theme-color;
  margin-left: -24px;
  position: absolute;
  top: 28px;
  left: 50%;
}
.bank-center-content > .bank-center-content-test {
  margin-top: 30px;
  display: flex;
}
.bank-center-content-test > .bank-center-content-test-img {
  width: 210px;
}
.bank-center-content-test > .bank-center-content-test-list {
  color: #666;
  width: 330px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}
.bank-center-content-test > .bank-center-content-test-list a {
  font-size: 16px;
  padding-left: 15px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}
.bank-center-content-test > .bank-center-content-test-list a::before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #999;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 7px;
}
// .bank-center > .el-row > .el-col:last-child .bank-center-content-type {
//   height: auto;
// }
// .bank-center > .el-row > .el-col:last-child .bank-center-content-test-list {
//   width: 900px;
// }
/* 题库中心 end */
</style>
